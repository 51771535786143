import { CookiesKeyEnum } from '@kmong-service/utils';

/**
 * 서버에서 accessToken 쿠키를 가져오는 함수
 * @description app router에서만 사용 가능한 함수로, page router에서는 사용 불가능합니다.
 * @returns accessToken 쿠키 값
 */
const getAccessTokenFromCookieForServer = async () => {
  const { cookies } = await import('next/headers');
  const accessTokenCookie = cookies().get(CookiesKeyEnum.KMONG_ACCESS_TOKEN);

  return accessTokenCookie?.value;
};

export default getAccessTokenFromCookieForServer;
