import { HeadersKeyEnum } from '@kmong-service/utils';
import { AxiosHeaders } from 'axios';
import { asyncHttpContext } from '../../utils/asyncHttpContext';
import { getAccessToken } from './jwt';
import {
  addPrefixWithToken, getRewriteURL, isLocal, isServerSide,
} from './utils';
import type { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import type { IncomingHttpHeaders, IncomingMessage } from 'http';

export function axiosRequestInterceptor(api: AxiosInstance) {
  api.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    if (isServerSide()) {
      const serverSideConfig = await setServerSideConfig(config);

      return serverSideConfig;
    }

    return setClientSideConfig(config);
  });
}

const setServerSideConfig = async (config: InternalAxiosRequestConfig) => {
  const { baseURL, url } = getRewriteURL(config.url ?? '');
  const asyncRequest = asyncHttpContext.req.get?.() ?? {} as IncomingMessage;

  if (!asyncRequest.headers) {
    return {
      ...config,
      baseURL,
      url,
    };
  }

  const {
    /**
     * @description LB에서 request 헤더 값중 host를 보고 이동시키기 때문에 명시적으로 host를 제거하여 door gateway로 이동시킵니다.
    * 호출 url https://api.market.dev.kmong.run/user-app/v2/penalty
    * 요청 request -> header -> host가 nextjs ssrPipe에서 market.dev.kmong.run로 잡힌상태로 이쪽으로 들어오기 때문에
    * xhr request는 host 값이 없다면 기본적으로 호출 url의 api.market.dev.kmong.run 으로 들어감
    * 그래서 host 를 지우면 알아서 api.market.dev.kmong.run 으로 들어가게됨
    * 문의사항 있을시 bk, derek에게 문의
    */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    host: _,
    ...asyncHeaders
  } = asyncRequest.headers;

  const accessToken = getAccessToken();
  const customHeaders = createCustomHeaders({
    baseHeaders: config.headers,
    asyncHeaders: !isLocal() ? asyncHeaders : undefined,
    customHeaders: accessToken ? {
      [HeadersKeyEnum.X_KMONG_AUTHORIZATION]: addPrefixWithToken(accessToken),
    } : undefined,
  });

  return {
    ...config,
    headers: customHeaders,
    baseURL,
    url,
  };
};

const setClientSideConfig = async (config: InternalAxiosRequestConfig) => {
  const { baseURL, url } = getRewriteURL(config.url ?? '');
  const accessToken = getAccessToken();

  if (accessToken) {
    const customHeaders = createCustomHeaders({
      baseHeaders: config.headers,
      customHeaders: {
        [HeadersKeyEnum.X_KMONG_AUTHORIZATION]: addPrefixWithToken(accessToken),
      },
    });

    return {
      ...config,
      headers: customHeaders,
      baseURL,
      url,
    };
  }

  return {
    ...config,
    baseURL,
    url,
  };
};

interface CreateCustomHeadersProps {
  baseHeaders: AxiosHeaders;
  asyncHeaders?: IncomingHttpHeaders;
  customHeaders?: Record<string, string>;
}

const createCustomHeaders = ({ baseHeaders, asyncHeaders, customHeaders }: CreateCustomHeadersProps) => {
  const headers = new AxiosHeaders(baseHeaders);

  if (asyncHeaders) {
    Object.entries(asyncHeaders).forEach(([key, value]) => {
      headers.set(key, value);
    });
  }

  if (customHeaders) {
    Object.entries(customHeaders).forEach(([key, value]) => {
      headers.set(key, value);
    });
  }

  return headers;
};
