import { cookies } from '@kmong-service/utils/src/cookies';
import { isServerSide } from '../../api';
import getAllCookiesForServer from './getAllCookiesForServer';

/**
 * 모든 쿠키를 가져오는 함수
 * @description 서버와 클라이언트의 분기처리가 되어있는 이유는, kmong-fetch가 서버와 클라이언트 모두에서 사용되기 때문입니다.
 * 서버에서는 app router에서만 사용 가능한 함수를 사용하고 (next/headers), 클라이언트에서는 브라우저 쿠키를 가져오는 함수를 사용합니다.
 * 기본적으로 next/headers는 app router에서만 사용 가능하며, 서버에서만 사용해야 합니다.
 * 서버와 클라이언트에서 동시에 사용되어야 하는 공통 함수의 경우 이러한 분기처리를 필수로 진행해야합니다.
 * @returns 모든 쿠키를 문자열로 반환
 */
const getAllCookies = async () => {
  if (isServerSide()) {
    return getAllCookiesForServer();
  }

  const allCookies = cookies.getItems() as Record<string, string>;
  const cookieString = Object.entries(allCookies).reduce(
    (acc, [name, value]) => `${name}=${encodeURIComponent(value)};${acc}`,
    '',
  );

  return cookieString;
};

export default getAllCookies;
