import { KmongFetchError } from '../../types';
import getAccessTokenFromCookie from './getAccessTokenFromCookie';

export const checkAuthForAuthRequired = async (requiredAuth: boolean) => {
  if (requiredAuth) {
    const token = await getAccessTokenFromCookie();
    if (!token) {
      throw new KmongFetchError({
        status: 401,
        data: 'Not Authorized',
      });
    }
  }
};
