/**
 * 서버에서 모든 쿠키를 가져오는 함수
 * @description app router에서만 사용 가능한 함수로, page router에서는 사용 불가능합니다.
 * @returns 모든 쿠키를 문자열로 반환
 */
const getAllCookiesForServer = async () => {
  const { cookies } = await import('next/headers');

  return cookies().getAll().reduce((acc, { name, value }) => `${name}=${encodeURIComponent(value)};${acc}`, '');
};

export default getAllCookiesForServer;
