import api from './api';
import { getAccessToken } from './api/lib/jwt';
import { isLocal } from './api/lib/utils';
import type { BusinessUserRole, OrganizationStatus } from './kmongbiz';

export type SocialProviderType = 'KAKAO' | 'NAVER' | 'FACEBOOK' | 'GOOGLE' | 'APPLE' | null;

export interface User {
  USERID: number;
  user_id_enc: string;
  email: string;
  funds: number;
  // 프로필작성 여부
  is_authenticated_seller_profile: boolean;
  // 프로필작성 여부
  is_completed_seller_profile: boolean;
  is_exist_gigs: boolean;
  is_mobile_auth: boolean;
  is_online: boolean;
  rating: string;
  thumbnail: string;
  username: string;
  vacation_expire_date?: string | null;
  net_promoter_score_target_type: 'BUYER' | 'SELLER' | null;
  fullname: string | null;
  seller_type: 'PERSONAL' | 'COMPANY' | '';
  // 전문가 인증 여부 (개인 사업자)
  is_authenticated_seller: boolean;
  // 전문가 약관 동의 여부
  is_seller_registration_agreed: boolean;
  social_provider: SocialProviderType;
  organization: Organization | null;
}

interface BusinessUser {
  role_id: number;
  role_type: BusinessUserRole;
  role_type_label: string;
}

export interface Organization {
  organization_id: number;
  company_name: string;
  status: OrganizationStatus;
  business_user: BusinessUser;
  has_penalty: boolean;
}

export interface LogoutSuccessResultCommon {
  USERID: number;
  accessToken: string;
  accessTokenExpiresAt: string;
  refreshToken: string;
  refreshTokenExpiresAt: string;
}

export interface LogoutSuccessResponse {
  data: LogoutSuccessResultCommon & {
    innerHTML: string;
    uri: string;
  };
  meta: {
    status: 1 | 2;
    msg: string;
  };
}

export type LogoutErrors = 'email' | 'password' | 'sweetAlert';
export interface LogoutFailResponse {
  meta: {
    status: -1;
    errors: {
      [key in LogoutErrors]?: string[];
    };
  };
}

export interface MemberTotal {
  member_total_count: number;
}

export type LogoutResponse = LogoutSuccessResponse | LogoutFailResponse;

export interface TokenRefreshResponse {
  accessToken: string;
  accessTokenExpiresAt: string;
  refreshToken: string | null;
  refreshTokenExpiresAt: string | null;
  userId: number;
  refreshTokenId: string;
  issuedAt: string;
}

export interface UpdatePasswordRequest {
  password: string;
  password_confirm: string;
  token: string;
}

export interface UpdatePasswordResponse {
  isChanged: boolean;
}

export interface LoginRequest {
  email: string;
  password: string;
  isRemember: boolean;
  authCode: string | null;
  multiFactorAuthKey: string | null;
}

type LoginErrorCode =
  | 'REQUEST_ERROR_INCORRECT_REQUEST_PARAMETER'
  | 'AUTH_ERROR_WRONG_PASSWORD'
  | 'AUTH_ERROR_FAIL_TO_LOGIN'
  | 'AUTH_ERROR_IS_SNS_USER'
  | 'AUTH_ERROR_DOESNT_EXIST_USER'
  | 'AUTH_ERROR_NEED_TO_MULTI_FACTOR_AUTH'
  | 'USER_ERROR_IS_WAITING_TO_LEAVE';

export interface LoginErrorResponse {
  result: string;
  errorCode: LoginErrorCode;
  code: string;
  message: string;
  data: {
    email: string[];
    password: string[];
  } | null;
}

export interface LoginSuccessResponse {
  multiFactorAuthKey: string;
  accessToken: string;
  accessTokenExpiresAt: string;
  refreshToken: string;
  refreshTokenExpiresAt: string;
}

const login = async (form: LoginRequest) => {
  const { data } = await api.post<LoginSuccessResponse>('/api/auth/v3/login', form);

  return data;
};

const tokenRefresh = (refreshToken: string) => api.patch<TokenRefreshResponse>('/api/auth/v2/jwt/refresh', {
  refreshToken,
});

const logout = (refreshToken: string = '') => api.delete<LogoutResponse>('/api/auth/v1/logout', {
  data: { refreshToken },
});

const GET_AUTH = '/api/user/v3/me';

const checkAuthToken = () => {
  const token = getAccessToken();
  if (!token) {
    throw new Error('Not authorized');
  }
};

const getAuth = async () => {
  if (isLocal()) {
    checkAuthToken();
  }

  const response = await api.get<User>(GET_AUTH);

  if (response.status === 204) {
    throw new Error('Not authorized');
  }

  return response.data;
};

type PlatformFeeAdjustType = 'PLUS' | 'ZERO';

interface Funds {
  platformFeeAdjustType: PlatformFeeAdjustType;
}

/**
 * MSA User의 funds 속성 안에 있는 platformFeeAdjustType 값을 가져옵니다.
 *
 * 참고:
 * 1. 추후 `/api/user/v3/me`가 /api/msa/user-app/user/v1/users/me(MSA User)로 변경 예정입니다.
 * 2. 지금은 응답 데이터 중 funds의 platformFeeAdjustType만 사용합니다.
 * 3. MSA User API 응답 모델이 확정되면 Funds 타입과 나머지 타입들도 모델링 되어야합니다.
 */
const getPlatformFee = async () => {
  const { data } = await api.get<{ funds: Funds }>('/api/msa/user-app/user/v1/users/me');

  return data.funds.platformFeeAdjustType;
};

const updatePassword = (form: UpdatePasswordRequest) => api.put<UpdatePasswordResponse>('/api/auth/v1/password', form);

interface PostForgotAccountPayload {
  mobile: string;
  realName: string;
}

interface PostForgotAccountResponse {
  message: string;
}

const postForgotAccount = async (payload: PostForgotAccountPayload) => {
  const { data } = await api.post<PostForgotAccountResponse>('/api/auth/v2/forgot/account', payload);

  return data?.message;
};

interface PostForgotPasswordPayload {
  email: string;
}

interface PostForgotPasswordResponse {
  message: string;
}

const postForgotPassword = async (payload: PostForgotPasswordPayload) => {
  const { data } = await api.post<PostForgotPasswordResponse>('/api/auth/v2/forgot/password', payload);

  return data?.message;
};

export const authApiURL = {
  GET_AUTH,
};

export interface SendMultiFactorAuthEmailPayload {
  email: string;
  password: string;
}

interface SendMultiFactorAuthEmailResponse {
  authCode: string | null;
}

const sendMultiFactorAuthEmail = async (payload: SendMultiFactorAuthEmailPayload) => {
  const { data } = await api.post<SendMultiFactorAuthEmailResponse>('/api/auth/v3/mfa/email', payload);

  return data;
};

export const authApi = {
  tokenRefresh,
  logout,
  getAuth,
  updatePassword,
  postForgotAccount,
  postForgotPassword,
  getPlatformFee,
  login,
  sendMultiFactorAuthEmail,
};
