import { CLOUDFRONT_URL } from '@kmong-service/utils';
import api from '../api';
import type { LinkInfo } from '../common';
import type { Gig } from '../gig';

export interface RootCategoryWithIcon {
  categoryId: number;
  name: string;
  icon: CategoryIcons;
}

interface CategoryIcons {
  mobile: string;
  desktop: string;
}

export interface RootCategoriesGroup {
  groupId: number;
  name: string;
  categories: RootCategoryWithIcon[];
}

interface GetRootCategoriesResponse {
  categories: RootCategoryWithIcon[];
}

const getRootCategories = async () => {
  const { data } = await api.get<GetRootCategoriesResponse>('/api/msa/gig-app/category/v1/root-categories');

  return data.categories;
};

export interface SubPlace {
  subPlaceId: number;
  placeName: string;
}

export interface FilterPlace {
  rootPlaceId: number;
  placeName: string;
  subPlaces: SubPlace[];
}

export interface GetPlacesResponse {
  places: FilterPlace[];
}

export const getPlaces = async (): Promise<GetPlacesResponse> => {
  const { data } = await api.get<GetPlacesResponse>('/api/msa/gig-app/place/v1/places');

  return data;
};

export interface CuratedContents {
  id: number;
  image: string;
  link: string;
  title: string;
}

export interface SubCategoryGroupCategory {
  categoryId: number;
  name: string;
  isNew: boolean;
  categoryGroups?: SubCategoryGroup[];
}
export interface SubCategoryGroup {
  id: number;
  parentCategoryId: number;
  name: string;
  isNew: boolean;
  categories?: SubCategoryGroupCategory[];
}

export interface GetSubCategoryGroupsResponse {
  categoryGroups: SubCategoryGroup[];
}

const getSubCategoryGroups = async (categoryId: number) => {
  const { data } = await api.get<GetSubCategoryGroupsResponse>(`/api/msa/gig-app/category/v1/categories/${categoryId}/side-navigation-bar`);

  return data;
};

export interface CategorySEOResponse {
  gigInfo: {
    totalGigCount: number;
    minGigPrice: number;
  };
  keywords: string;
  categoryInfo: {
    rootCategoryId: number;
    rootCategoryName: string;
    subCategoryId: number | null;
    subCategoryName: string | null;
    thirdCategoryId: number | null;
    thirdCategoryName: string | null;
  };
}

const getCategorySEO = async (categoryId: number) => {
  const { data } = await api.get<CategorySEOResponse>(`/api/msa/gig-app/category/v1/categories/${categoryId}/SEO`);

  return data;
};

interface GetPopularCategoriesResponse {
  items: PopularCategory[];
}

interface PopularCategory {
  categoryId: number;
  name: string;
}

const getPopularCategories = async (categoryId: number) => {
  const { data } = await api.get<GetPopularCategoriesResponse>(`/api/msa/gig-app/category/v1/categories/${categoryId}/popular-categories`);

  return data;
};

export interface GetCategoryGroupsResponse {
  categoryGroups: CategoryGroup[];
}

export interface CategoryGroup {
  id: number;
  parentCategoryId: number;
  name: string;
  isNew: boolean;
  categories: CategoryGroupCategory[];
}

export interface CategoryGroupCategory {
  categoryId: number;
  name: string;
  isNew: boolean;
  /** 앱에서만 사용하는 프로퍼티 */
  icon: {
    mobile: string;
    desktop: string;
  };
  banner: HeaderBannerBase;
  categoryGrids: CategoryGridsBase[];
}

export interface CategoryGridsBase {
  column: number;
  categoryGroups: CategoryGridGroup[];
}

export interface CategoryGridGroup {
  id: number;
  parentCategoryId: number;
  name: string;
  isNew: boolean;
  categories: CategoryGridCategory[];
}

export interface CategoryGridCategory {
  categoryId: number;
  name: string;
  isNew: boolean;
}

export interface HeaderBannerBase {
  id: number;
  categoryId: number;
  isNewTabForDesktop: boolean;
  linkInfo: LinkInfo;
  imageUrl: string;
  title: string;
}

const getCategoryGroups = async () => {
  const { data } = await api.get<GetCategoryGroupsResponse>('/api/msa/gig-app/category/v1/global-navigation-bar');

  return data;
};

export interface CategoryGigListParams {
  categoryId?: string;
  page?: string;
  sort?: string;
  company?: string;
  is_prime?: string;
  has_portfolio?: string;
  is_contactable?: string;
  is_fast_reaction?: boolean;
  ratings?: string;
  meta?: string;
  random_number?: number;
}

export interface CategoryGigList {
  gigs: Gig[];
  total: number;
  last_page: number;
  random_number: number;
}

const getCategoryGigs = async (categoryId: number, params: CategoryGigListParams) => {
  const { data } = await api.get<CategoryGigList>(
    `/api/v5/categories/${categoryId}/gigs`,
    {
      params: {
        ...params,
        selected_gig_data: params.meta, // meta에서 selected_gig_data로 필드 이름이 변경 되었음
      },
    },
  );

  return data;
};

export interface GetBestGigCategoriesResponse {
  parentCategories: ParentCategory[];
}

export interface ParentCategory {
  parentCategoryId: number;
  parentCategoryName: string;
  title: string;
  categories: TopSecondCategory[];
}

export interface TopSecondCategory {
  name: string;
  imageUrl: string;
  linkInfo: NewTabLinkInfo;
}

interface NewTabLinkInfo extends LinkInfo {
  isNewTab: boolean;
}

const getBestGigCategories = async (): Promise<GetBestGigCategoriesResponse> => {
  const data = await fetch(`${CLOUDFRONT_URL}/assets/recommend/best-gig/top-child-categories/v2/top-child-categories.json`);

  return data.json();
};

export const categoryApi = {
  getRootCategories,
  getPlaces,
  getSubCategoryGroups,
  getCategorySEO,
  getPopularCategories,
  getCategoryGigs,
  getBestGigCategories,
  getCategoryGroups,
};
