/* eslint-disable @typescript-eslint/no-unused-vars */
export const ORDER_STATUS_TYPE = {
  READY_ORDER: '요청사항 미작성',
  ONGOING: '진행중',
  MUTUAL_CANCELLATION_REQUEST: '취소 요청',
  TIME_MODIFICATION: '작업일 연장 요청',
  ADDITIONAL_PAY_REQUEST: '추가 결제 요청',
  INREVISION: '진행중',
  CANCELED_BY_BUYER: '주문 취소',
  CANCELED_BY_SELLER: '주문 취소',
  SYS_CANCELED: '주문 취소',
  TROUBLESHOOTING: '분쟁 조정 중',
  RESOLUTION_PROBLEM: '문제 해결',
  QUALITY_GUARANTEED: '품질보장 검토 중',
} as const;

const BUYER_COMPLETED = {
  COMPLETED: '구매 확정',
  SYS_COMPLETED: '자동 구매 확정',
} as const;

const BUYER_DELIVERED_GIG = {
  DELIVERED: '작업물 도착',
} as const;

const BUYER_DELIVERED_VOD = {
  DELIVERED: '수강권 도착',
} as const;

const BUYER_DELIVERED_EBOOK = {
  DELIVERED: '전자책 도착',
} as const;

const BUYER_ORDER_STATUS_TYPE = {
  ...ORDER_STATUS_TYPE,
  ...BUYER_DELIVERED_GIG,
  ...BUYER_COMPLETED,
} as const;

const SELLER_DELIVERED_GIG = {
  DELIVERED: '작업물 발송',
} as const;

const SELLER_COMPLETED = {
  COMPLETED: '거래 완료',
  SYS_COMPLETED: '거래 완료',
} as const;

const SELLER_ORDER_STATUS_TYPE = {
  ...ORDER_STATUS_TYPE,
  ...SELLER_DELIVERED_GIG,
  ...SELLER_COMPLETED,
} as const;

export type BuyerDeliveredLabel = typeof BUYER_DELIVERED_GIG.DELIVERED;
export type VODDeliveredLabel = typeof BUYER_DELIVERED_VOD.DELIVERED;
export type EBOOKDeliveredLabel = typeof BUYER_DELIVERED_EBOOK.DELIVERED;
export type BuyerOrderStatusType = keyof typeof BUYER_ORDER_STATUS_TYPE;

export type BuyerOrderStatusLabel = typeof
  BUYER_ORDER_STATUS_TYPE[BuyerOrderStatusType] |
  BuyerDeliveredLabel |
  VODDeliveredLabel |
  EBOOKDeliveredLabel;

export type SellerOrderStatusType = keyof typeof SELLER_ORDER_STATUS_TYPE;
export type SellerOrderStatusLabel = typeof SELLER_ORDER_STATUS_TYPE[SellerOrderStatusType];

export const BUYER_STATUS_SEARCH_OPTIONS = {
  ALL: '전체 상태',
  NOT_INST: '요청사항 미작성',
  ONGOING: '진행중',
  NOT_REVIEWED: '작성 가능한 리뷰',
  DELIVERED: '작업물 도착',
  COMPLETED: '구매 확정',
  CANCELED: '주문 취소',
  CANCEL_REQUEST_WITH_RESOLUTION_PROBLEM: '취소·문제해결',
  TROUBLESHOOTING: '분쟁 조정 중',
} as const;

export type BuyerStatusSearchOptions = keyof typeof BUYER_STATUS_SEARCH_OPTIONS;

export const SELLER_STATUS_SEARCH_OPTIONS = {
  ALL: '전체 상태',
  NOT_INST: '요청사항 미작성',
  ONGOING: '진행중',
  LATE_DELIVERY: '진행중 (발송 지연)',
  INREVISION: '수정 요청',
  DELIVERED: '작업물 발송',
  COMPLETED: '거래 완료',
  CANCELED: '주문 취소',
  CANCEL_REQUEST_WITH_RESOLUTION_PROBLEM: '취소·문제해결',
  TROUBLESHOOTING: '분쟁 조정 중',
} as const;

export type SellerStatusSearchOptions = keyof typeof SELLER_STATUS_SEARCH_OPTIONS;
