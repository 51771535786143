import api from './api';
import { isKmongError } from './types';
import type { SocialProviderType } from './auth';

export interface UserSelectedJobsResponse {
  job_type_id: null | number;
  job_sector_id: null | number;
  job_type_title: null | string;
  job_sector_title: null | string;
}

const getUserSelectedJobs = async () => {
  const { data } = await api.get<UserSelectedJobsResponse>('/api/user/v2/user-jobs');

  return data;
};

interface JobTypePayload {
  job_type_id: number;
}

const putJobType = async (payload: JobTypePayload) => {
  await api.patch('/api/user/v2/job-type', payload);
};

interface JobSectorPayload {
  job_sector_id: number;
}

const putJobSector = async (payload: JobSectorPayload) => {
  await api.patch('/api/user/v2/job-sector', payload);
};

export interface JobTypesResponse {
  job_types: Array<{
    id: number;
    title: string;
  }>;
}

const getJobTypes = async () => {
  const { data } = await api.get<JobTypesResponse>('/api/user/v2/job/types');

  return data.job_types;
};

export interface JobSectorsResponse {
  job_sectors: Array<{
    id: number;
    title: string;
  }>;
}

const getJobSectors = async () => {
  const { data } = await api.get<JobSectorsResponse>('/api/user/v2/job/sectors');

  return data.job_sectors;
};

export interface SellerRegistrationResponse {
  is_agreed: boolean;
}

const getSellerRegistration = async () => {
  const { data } = await api.get<SellerRegistrationResponse>('/api/user/v2/seller-registration');

  return data.is_agreed;
};

export interface SellerRegistrationPayload {
  is_agreed: boolean;
}

const postSellerRegistration = async (payload: SellerRegistrationPayload) => {
  const { data } = await api.post<SellerRegistrationResponse>('/api/user/v2/seller-registration', payload);

  return data.is_agreed;
};

export interface UserSettingsResponse {
  USERID: number;
  username: string;
  is_able_to_edit_username: boolean;
  username_updated_at: string | null;
  email: string;
  is_authenticated_email: boolean;
  profile_picture: string;
  fullname: string;
  mobile: string;
  is_mobile_authorized: boolean;
  job_type_id: number;
  job_sector_id: number;
  av_time_from: number;
  av_time_to: number;
  social_provider: SocialProviderType;
}

const getUserSettings = async () => {
  const { data } = await api.get<UserSettingsResponse>('/api/user/v2/user/settings');

  return data;
};

const getUserSettingsPrivacy = async () => {
  const { data } = await api.get<UserSettingsResponse>('/api/user/v2/user/settings/privacy');

  return data;
};

export interface SellerSettingsResponse {
  seller_type: string;
  grade: string;
  profile_completed_percent: number;
  contact_number: string;
  av_time_from: number;
  av_time_to: number;
  safe_number: null | string;
  is_open_mobile: boolean;
}

const getSellerSettings = async () => {
  const { data } = await api.get<SellerSettingsResponse>('/api/user/v2/seller/settings');

  return data;
};

const getSellerSettingsPrivacy = async () => {
  const { data } = await api.get<SellerSettingsResponse>('/api/user/v2/seller/settings/privacy');

  return data;
};

export interface CompanyUser {
  company_number: string;
  company_name: string;
  company_category_id: number;
  company_category_name: string;
  tax_type_name: string;
  tax_type: string;
  representative_name: string;
  company_address: string;
  company_type: string;
  company_kind: string;
  company_email: string;
  company_contact_username: string;
  company_contact_number: string;
  company_bankcode: string;
  company_account_number: string;
  company_depositor: string;
  company_id: number;
  company_bank_id: number;
  status: 'APPROVED' | 'WAITING' | 'REJECTED' | 'READY';
  updated_at: string;
}

export interface SellerCertificationsResponse {
  seller_type: 'PERSONAL' | 'COMPANY';
  is_available_edit: boolean;
  social_security_number: {
    fullname: string;
    social_security_number: string;
  };
  withdrawn_account: {
    depositor: string;
    bank_code: string | null;
    account_number: string | null;
  };
  company?: CompanyUser;
}

const getSellerCertifications = async () => {
  const { data } = await api.get<SellerCertificationsResponse>('/api/user/v2/seller/certifications');

  return data;
};

const getSellerCertificationsPrivacy = async () => {
  const { data } = await api.get<SellerCertificationsResponse>('/api/user/v2/seller/certifications/privacy');

  return data;
};

interface IsOpenSafeNumberResponse {
  is_mapped: boolean;
}

const getIsOpenSafeNumber = async () => {
  const { data } = await api.get<IsOpenSafeNumberResponse>('/api/user/v2/safe-number');

  return data.is_mapped;
};

interface ContactNumberPayload {
  contact_number: string;
}

const patchContactNumber = async (payload: ContactNumberPayload) => {
  try {
    await api.patch('/api/user/v2/contact-number', payload);

    return '';
  } catch (error) {
    if (isKmongError(error)) {
      return error.response.data.message ?? '사용할 수 없습니다.';
    }

    return '사용할 수 없습니다.';
  }
};

interface OpenMobilePayload {
  open_mobile: string;
}

const patchOpenMobile = async (payload: OpenMobilePayload) => {
  await api.patch('/api/user/v2/open-mobile', payload);
};

interface AvailableTimePayload {
  av_time_from: number;
  av_time_to: number;
}

const patchAvailableTime = async (payload: AvailableTimePayload) => {
  await api.patch('/api/user/v2/available-time', payload);
};

const getValidateUsername = async (username: string): Promise<string> => {
  try {
    await api.get(`/api/user/v2/username?username=${username}`);

    return '';
  } catch (error) {
    if (isKmongError<number>(error)) {
      if (error.response.data.code === 99002) {
        return '';
      }

      return error.response.data.message ?? '사용할 수 없는 닉네임입니다.';
    }

    return '사용할 수 없는 닉네임입니다.';
  }
};

const patchUsername = async (username: string): Promise<string> => {
  try {
    await api.patch('/api/user/v2/username', { username });

    return '';
  } catch (error) {
    if (isKmongError(error)) {
      return error.response.data.message ?? '사용할 수 없는 닉네임입니다.';
    }

    return '사용할 수 없는 닉네임입니다.';
  }
};

interface PostSecurityNumberPayload {
  social_security_number: string;
}

const postPostSecurityNumber = async (payload: PostSecurityNumberPayload): Promise<string> => {
  try {
    await api.post('/api/auth/v1/social-security-number', payload);

    return '';
  } catch (error) {
    if (isKmongError(error)) {
      return error.response.data.message ?? '잘못된 주민등록번호 입니다.';
    }

    return '잘못된 주민등록번호 입니다.';
  }
};

interface PostCompanyRegistrationNumberPayload {
  company_name: string;
  company_number: string;
  company_type_id?: string;
}

export interface CompanyRegistrationResponse {
  company_category_id: number;
  company_category_name: string;
  company_name: string;
  company_number: string;
  tax_type: string;
  tax_type_name: string;
}

const postCompanyRegistrationNumber = async (payload: PostCompanyRegistrationNumberPayload) => {
  try {
    const { data } = await api.post<CompanyRegistrationResponse>('/api/auth/v1/company-registration-number', payload);

    return data;
  } catch (error) {
    if (isKmongError(error)) {
      return error.response.data.message ?? '사업자 번호를 찾을 수 없습니다.';
    }

    return '사업자 번호를 찾을 수 없습니다.';
  }
};

interface WidthdrawnAccountSuccess {
  USERID: number;
  depositor: string;
  bank_code: string;
  account_number: string;
  updated_at: string;
  created_at: string;
  id: number;
}

interface WidthdrawnAccountPayload {
  bank_code: string;
  account_number: string;
}

const postWithdrawnAccount = async (payload: WidthdrawnAccountPayload): Promise<string> => {
  try {
    await api.post<WidthdrawnAccountSuccess>('/api/auth/v1/withdrawn-account', payload);

    return '';
  } catch (error) {
    if (isKmongError(error)) {
      return error.response.data.message ?? '잘못된 계좌번호 입니다.';
    }

    return '잘못된 계좌번호 입니다.';
  }
};

interface WithdrawStatusResponse {
  is_exists: boolean;
}

const getWithdrawStatus = async () => {
  const { data } = await api.get<WithdrawStatusResponse>('/api/payments/v2/withdraw');

  return data?.is_exists ?? false;
};

const postEmailAuthCode = async () => {
  try {
    await api.post('/api/auth/v1/email/verification');

    return true;
  } catch {
    return false;
  }
};

interface Payload {
  code: number;
}

const postVerifyEmailAuthCode = async (payload: Payload) => {
  try {
    await api.post(`/api/auth/v1/email/verification-code?code=${payload.code}`);

    return '';
  } catch (error) {
    if (isKmongError(error)) {
      return error.response.data.message ?? '유효하지 않는 인증번호 입니다.';
    }

    return '유효하지 않는 인증번호 입니다.';
  }
};

export interface PostCompanyUserPayload {
  company_name: string;
  company_number: string;
  tax_type: string;
  tax_type_name: string;
  representative_name: string;
  company_category_id: string;
  company_category_name: string;
  company_address: string;
  company_type: string;
  company_kind: string;
  company_email: string;
  company_contact_number: string;
  company_bankcode: string;
  company_account_number: string;
  company_depositor: string;
  company_id: string;
  company_bank_id: string;
  company_contact_username: string;
  company_auth: boolean;
}

const postCompanyUser = async (payload: PostCompanyUserPayload): Promise<string> => {
  try {
    await api.post('/api/user/v2/company-user', payload);

    return '';
  } catch (error) {
    if (isKmongError(error)) {
      return error.response.data.message ?? '저장중 오류가 발생했습니다.';
    }

    return '저장중 오류가 발생했습니다.';
  }
};

export interface MailSettingsResponse {
  ads_email: 0 | 1;
  ads_sms: 0 | 1;
  ads_app_push: 0 | 1;
  custom_project_proposal_email: 0 | 1;
  custom_project_proposal_sms: 0 | 1;
  custom_project_proposal_app_push: 0 | 1;
  custom_project_request_email: 0 | 1;
  custom_project_request_sms: 0 | 1;
  custom_project_request_app_push: 0 | 1;
}

const getMailSettings = async () => {
  const { data } = await api.get<MailSettingsResponse>('/api/user/v2/mail-settings');

  return data;
};

interface MailSettingsPayload {
  target: string;
  value: '0' | '1';
}

const patchMailSettings = async (payload: MailSettingsPayload) => {
  await api.patch('/api/user/v2/mail-settings', payload);
};

interface PatchChangePasswordPayload {
  current_password: string;
  password: string;
  password_confirm: string;
}

const patchChangePassword = async (payload: PatchChangePasswordPayload) => {
  try {
    await api.patch('/api/user/v3/password', payload);

    return '';
  } catch (error) {
    if (isKmongError(error)) {
      return error.response.data.message ?? '오류가 발생했습니다.';
    }

    return '오류가 발생했습니다.';
  }
};

interface CheckPossibleLeaveResponse {
  afunds: number;
  funds: number;
  is_exists_ongoing_order: boolean;
  is_exists_penalty: boolean;
  is_possible_leave: boolean;
}

const checkPossibleLeave = async () => {
  const { data } = await api.get<CheckPossibleLeaveResponse>('/api/user/v2/leaved');

  return data;
};

interface LeavePayload {
  because: string;
  email: string;
  because_etc: string;
}

interface LeaveResponse {
  is_leave_process_success: boolean;
}

const leave = async (payload: LeavePayload): Promise<string> => {
  try {
    await api.delete<LeaveResponse>('/api/user/v2/leaved', {
      data: payload,
    });

    return '';
  } catch (error) {
    if (isKmongError(error)) {
      const { message, code } = error.response.data;

      if (String(code) === '10113') {
        return '클릭업 광고를 종료하고 다시 신청해주세요.';
      }

      return message ?? '오류가 발생했습니다.';
    }

    return '오류가 발생했습니다.';
  }
};

interface BanksResponse {
  banks: Array<{
    code: string;
    name: string;
    type: string;
    web: string;
    android: string;
    ios: string;
  }>;
}

const getBanks = async () => {
  const { data } = await api.get<BanksResponse>('/api/payments/v2/banks');

  return data.banks;
};

export interface PostUserRefundAccountsData {
  bankName: string;
  bankCode: string;
  accountType: 'PERSONAL' | 'BUSINESS';
  accountNumber: string;
  depositor: string;
}

const postUserRefundAccounts = async (data: PostUserRefundAccountsData) => {
  await api.post<PostUserRefundAccountsData>('/api/msa/order-app/payment/v1/payments/buyer/refund/user-refund-accounts', data);
};

interface UserRefundAccountDetail extends PostUserRefundAccountsData {
  userRefundAccountId: number;
}

export interface GetUserRefundAccountsResponse {
  userRefundAccountDetail: UserRefundAccountDetail | null;
}

const getUserRefundAccounts = async () => {
  const { data } = await api.get<GetUserRefundAccountsResponse>('/api/msa/order-app/payment/v1/payments/buyer/refund/user-refund-accounts');

  return data;
};

interface RefundBank {
  code: string;
  name: string;
}

export interface GetRefundBanksResponse {
  banks: RefundBank[];
}

const getRefundBanks = async () => {
  const { data } = await api.get<GetRefundBanksResponse>('/api/msa/order-app/payment/v1/payments/buyer/refund-banks');

  return data;
};

const postUserAccountsValidate = async (data: PostUserRefundAccountsData) => {
  const response = await api.post('/api/payment/buyer/refund/v1/user-accounts/validate', data);

  return response.data;
};

const deleteUserRefundAccounts = async (userRefundAccountId: number) => {
  api.delete(`/api/msa/order-app/payment/v1/payments/buyer/refund/user-refund-accounts/${userRefundAccountId}`);
};

interface GetIsResettableRefundAccountsResponse {
  isResettable: boolean;
}

const getIsResettableRefundAccounts = async (userRefundAccountId: number) => {
  const { data } = await api.get<GetIsResettableRefundAccountsResponse>(`/api/msa/order-app/payment/v1/payments/buyer/refund/user-refund-accounts/${userRefundAccountId}/is-resettable`);

  return data;
};

export const myProfileApi = {
  putJobType,
  putJobSector,
  getUserSelectedJobs,
  getIsOpenSafeNumber,
  getJobTypes,
  getJobSectors,
  getValidateUsername,
  patchOpenMobile,
  patchUsername,
  patchAvailableTime,
  patchContactNumber,
  postPostSecurityNumber,
  postCompanyRegistrationNumber,
  postWithdrawnAccount,
  getWithdrawStatus,
  postEmailAuthCode,
  postVerifyEmailAuthCode,
  getMailSettings,
  patchMailSettings,
  patchChangePassword,
  checkPossibleLeave,
  leave,
  getSellerRegistration,
  postSellerRegistration,
  getUserSettings,
  getSellerSettings,
  getSellerCertifications,
  getBanks,
  postCompanyUser,
  getUserRefundAccounts,
  postUserRefundAccounts,
  getRefundBanks,
  postUserAccountsValidate,
  deleteUserRefundAccounts,
  getIsResettableRefundAccounts,
  getUserSettingsPrivacy,
  getSellerSettingsPrivacy,
  getSellerCertificationsPrivacy,
};
