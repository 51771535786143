import type { GetSearchGigResultResponse } from '../search';

export const searchGigResultMock: GetSearchGigResultResponse = {
  totalItemCount: 23533,
  lastPage: 1177,
  currentPage: 1,
  perPage: 20,
  gigs: [
    {
      gigId: 447129,
      currentCategoryId: 101,
      title: '크몽어워즈수상ㅣ로고제작 전문기업',
      price: 70000,
      advertisementType: '',
      labels: [
        'KMONG_AWARDS',
      ],
      images: [
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/eQEhB1715852302.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/Js1f31715852307.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/jQNqS1715852313.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/andAj1715852318.jpg',
      ],
      seller: {
        userId: 2500899,
        nickname: '제이디컴퍼니',
        grade: 'NEW',
        thumbnail: 'https://d2v80xjmx68n4w.cloudfront.net/members/thumbs/b8dvR1703220127.png',
        isOnline: true,
        isAvailableTax: true,
        isFastReaction: true,
        isNowContactable: false,
      },
      review: {
        reviewAverage: 4.9,
        reviewCount: 512,
      },
      category: {
        rootCategoryId: 1,
        rootCategoryName: '디자인',
        subCategoryId: 101,
        subCategoryName: '로고 디자인',
        thirdCategoryId: null,
        thirdCategoryName: null,
      },
      hasVideos: false,
      hasPackages: true,
      isPrime: false,
      isAvailableCalling: false,
      isCurrentUserBookmark: false,
      gigCardUuid: 'GIG_CARD_SEARCH_RESULT_2024060419040826913_juxdmj',
      kmongAd: null,
      isQualityGuaranteed: false,
    },
    {
      gigId: 95958,
      currentCategoryId: 101,
      title: '로고제작 랭키1위 KS 11년연속1위 크몽어워즈수상',
      price: 150000,
      advertisementType: '',
      labels: [],
      images: [
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/vvb3N1715328792.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/FSprY1715328796.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/BbQFM1715328799.jpg',
      ],
      seller: {
        userId: 242854,
        nickname: '지구컴즈',
        grade: 'NEW',
        thumbnail: 'https://d2v80xjmx68n4w.cloudfront.net/members/thumbs/0wnAw1704763923.jpg',
        isOnline: true,
        isAvailableTax: true,
        isFastReaction: true,
        isNowContactable: false,
      },
      review: {
        reviewAverage: 4.9,
        reviewCount: 2487,
      },
      category: {
        rootCategoryId: 1,
        rootCategoryName: '디자인',
        subCategoryId: 101,
        subCategoryName: '로고 디자인',
        thirdCategoryId: null,
        thirdCategoryName: null,
      },
      hasVideos: false,
      hasPackages: true,
      isPrime: true,
      isAvailableCalling: false,
      isCurrentUserBookmark: false,
      gigCardUuid: 'GIG_CARD_SEARCH_RESULT_2024060419040826913_juxdmj',
      kmongAd: null,
      isQualityGuaranteed: false,
    },
    {
      gigId: 283530,
      currentCategoryId: 101,
      title: '크몽어워즈 대상 어워즈2회 수상 / 독보적인 퀄리티',
      price: 75000,
      advertisementType: '',
      labels: [
        'KMONG_AWARDS',
      ],
      images: [
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/Au0ZS1717467443.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/6OjVz1717467464.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/45Ba71717467478.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/jE29n1717467503.jpg',
      ],
      seller: {
        userId: 492214,
        nickname: '그리미아트디자인',
        grade: 'MASTER',
        thumbnail: 'https://d2v80xjmx68n4w.cloudfront.net/members/thumbs/0WcZN1609436763.jpg',
        isOnline: true,
        isAvailableTax: true,
        isFastReaction: false,
        isNowContactable: false,
      },
      review: {
        reviewAverage: 4.9,
        reviewCount: 3807,
      },
      category: {
        rootCategoryId: 1,
        rootCategoryName: '디자인',
        subCategoryId: 101,
        subCategoryName: '로고 디자인',
        thirdCategoryId: null,
        thirdCategoryName: null,
      },
      hasVideos: false,
      hasPackages: true,
      isPrime: false,
      isAvailableCalling: false,
      isCurrentUserBookmark: false,
      gigCardUuid: 'GIG_CARD_SEARCH_RESULT_2024060419040826913_juxdmj',
      kmongAd: null,
      isQualityGuaranteed: false,
    },
    {
      gigId: 242649,
      currentCategoryId: 101,
      title: '크몽어워즈수상ㅣ선착순 단 3분만 로고 의뢰 받습니다.',
      price: 95000,
      advertisementType: '',
      labels: [
        'KMONG_AWARDS',
      ],
      images: [
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/ZcjfW1716221538.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/nJHqh1716221544.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/LweH51716221548.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/z9d5T1716221552.jpg',
      ],
      seller: {
        userId: 957125,
        nickname: '원스팟디자인',
        grade: 'MASTER',
        thumbnail: 'https://d2v80xjmx68n4w.cloudfront.net/members/thumbs/SEvsj1699602669.png',
        isOnline: true,
        isAvailableTax: true,
        isFastReaction: true,
        isNowContactable: false,
      },
      review: {
        reviewAverage: 4.9,
        reviewCount: 3071,
      },
      category: {
        rootCategoryId: 1,
        rootCategoryName: '디자인',
        subCategoryId: 101,
        subCategoryName: '로고 디자인',
        thirdCategoryId: null,
        thirdCategoryName: null,
      },
      hasVideos: false,
      hasPackages: true,
      isPrime: true,
      isAvailableCalling: false,
      isCurrentUserBookmark: false,
      gigCardUuid: 'GIG_CARD_SEARCH_RESULT_2024060419040826913_juxdmj',
      kmongAd: null,
      isQualityGuaranteed: false,
    },
    {
      gigId: 546346,
      currentCategoryId: 10601,
      title: '모든 PPT를 세련되게 만들어드립니다.',
      price: 10000,
      advertisementType: '',
      labels: [],
      images: [
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/f63Zu1708082925.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/ScWTQ1708344527.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/cPSrX1708344536.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/TTNxU1708344544.jpg',
      ],
      seller: {
        userId: 2375320,
        nickname: '잉글레이션',
        grade: 'MASTER',
        thumbnail: 'https://d2v80xjmx68n4w.cloudfront.net/members/thumbs/E0kjD1669114443.jpg',
        isOnline: true,
        isAvailableTax: true,
        isFastReaction: true,
        isNowContactable: false,
      },
      review: {
        reviewAverage: 4.8,
        reviewCount: 189,
      },
      category: {
        rootCategoryId: 1,
        rootCategoryName: '디자인',
        subCategoryId: 106,
        subCategoryName: 'PPT·인포그래픽',
        thirdCategoryId: 10601,
        thirdCategoryName: 'PPT',
      },
      hasVideos: false,
      hasPackages: true,
      isPrime: false,
      isAvailableCalling: false,
      isCurrentUserBookmark: false,
      gigCardUuid: 'GIG_CARD_SEARCH_RESULT_2024060419040826913_juxdmj',
      kmongAd: null,
      isQualityGuaranteed: false,
    },
    {
      gigId: 59753,
      currentCategoryId: 10601,
      title: 'PPT제작, 고객이 원하고 청중이 원하는 멋진 작업',
      price: 5000,
      advertisementType: '',
      labels: [],
      images: [
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/i2oqT1580286880.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/JHyr51689834342.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/zVHtW1689834347.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/vZG851689834350.jpg',
      ],
      seller: {
        userId: 122275,
        nickname: '심퍼니즘',
        grade: 'MASTER',
        thumbnail: 'https://d2v80xjmx68n4w.cloudfront.net/members/thumbs/a3sO01581419637.jpg',
        isOnline: true,
        isAvailableTax: true,
        isFastReaction: false,
        isNowContactable: false,
      },
      review: {
        reviewAverage: 4.8,
        reviewCount: 3079,
      },
      category: {
        rootCategoryId: 1,
        rootCategoryName: '디자인',
        subCategoryId: 106,
        subCategoryName: 'PPT·인포그래픽',
        thirdCategoryId: 10601,
        thirdCategoryName: 'PPT',
      },
      hasVideos: false,
      hasPackages: true,
      isPrime: false,
      isAvailableCalling: false,
      isCurrentUserBookmark: false,
      gigCardUuid: 'GIG_CARD_SEARCH_RESULT_2024060419040826913_juxdmj',
      kmongAd: null,
      isQualityGuaranteed: false,
    },
    {
      gigId: 215649,
      currentCategoryId: 132,
      title: '"디자인부터 인쇄까지" 브로슈어 카탈로그 리플렛 디자인',
      price: 40000,
      advertisementType: '',
      labels: [],
      images: [
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/8AWgH1700221809.jpg',
      ],
      seller: {
        userId: 875788,
        nickname: 'Designstep',
        grade: 'MASTER',
        thumbnail: 'https://d2v80xjmx68n4w.cloudfront.net/members/thumbs/ASYWx1663658846.jpg',
        isOnline: true,
        isAvailableTax: true,
        isFastReaction: true,
        isNowContactable: false,
      },
      review: {
        reviewAverage: 4.9,
        reviewCount: 802,
      },
      category: {
        rootCategoryId: 1,
        rootCategoryName: '디자인',
        subCategoryId: 132,
        subCategoryName: '전단지·포스터·인쇄물',
        thirdCategoryId: null,
        thirdCategoryName: null,
      },
      hasVideos: false,
      hasPackages: false,
      isPrime: false,
      isAvailableCalling: false,
      isCurrentUserBookmark: false,
      gigCardUuid: 'GIG_CARD_SEARCH_RESULT_2024060419040826913_juxdmj',
      kmongAd: null,
      isQualityGuaranteed: false,
    },
    {
      gigId: 193113,
      currentCategoryId: 11301,
      title: '판매로 이어지는 효과적인 상세페이지 디자인 서비스',
      price: 10000,
      advertisementType: '',
      labels: [],
      images: [
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/p8Bk31576130708.jpg',
      ],
      seller: {
        userId: 640565,
        nickname: '과연',
        grade: 'MASTER',
        thumbnail: 'https://d2v80xjmx68n4w.cloudfront.net/members/thumbs/WJ5Mg1639650637.png',
        isOnline: false,
        isAvailableTax: false,
        isFastReaction: true,
        isNowContactable: false,
      },
      review: {
        reviewAverage: 4.9,
        reviewCount: 1365,
      },
      category: {
        rootCategoryId: 1,
        rootCategoryName: '디자인',
        subCategoryId: 113,
        subCategoryName: '상세페이지',
        thirdCategoryId: 11301,
        thirdCategoryName: '상세페이지 디자인',
      },
      hasVideos: false,
      hasPackages: true,
      isPrime: false,
      isAvailableCalling: false,
      isCurrentUserBookmark: false,
      gigCardUuid: 'GIG_CARD_SEARCH_RESULT_2024060419040826913_juxdmj',
      kmongAd: null,
      isQualityGuaranteed: false,
    },
    {
      gigId: 333836,
      currentCategoryId: 133,
      title: '당일제작 현수막, 배너 깔끔하게 디자인해 드립니다.',
      price: 5000,
      advertisementType: '',
      labels: [],
      images: [
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/S0Sh91715496270.jpg',
      ],
      seller: {
        userId: 1653151,
        nickname: '몽땅광고',
        grade: 'NEW',
        thumbnail: 'https://d2v80xjmx68n4w.cloudfront.net/members/thumbs/LMSr71691295134.jpg',
        isOnline: true,
        isAvailableTax: false,
        isFastReaction: true,
        isNowContactable: false,
      },
      review: {
        reviewAverage: 4.9,
        reviewCount: 156,
      },
      category: {
        rootCategoryId: 1,
        rootCategoryName: '디자인',
        subCategoryId: 133,
        subCategoryName: '현수막·X배너',
        thirdCategoryId: null,
        thirdCategoryName: null,
      },
      hasVideos: false,
      hasPackages: true,
      isPrime: false,
      isAvailableCalling: false,
      isCurrentUserBookmark: false,
      gigCardUuid: 'GIG_CARD_SEARCH_RESULT_2024060419040826913_juxdmj',
      kmongAd: null,
      isQualityGuaranteed: false,
    },
    {
      gigId: 455556,
      currentCategoryId: 101,
      title: '고급 감성로고 쇼핑몰 브랜드 뷰티 로고 디자인 제작',
      price: 98000,
      advertisementType: '',
      labels: [],
      images: [
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/CJuWz1695460326.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/OtDPU1695460335.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/TboN51695626684.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/Ss2jZ1695626697.jpg',
      ],
      seller: {
        userId: 2165043,
        nickname: 'NVE',
        grade: 'LEVEL3',
        thumbnail: 'https://d2v80xjmx68n4w.cloudfront.net/members/thumbs/HS6CR1679974279.jpg',
        isOnline: false,
        isAvailableTax: true,
        isFastReaction: false,
        isNowContactable: false,
      },
      review: {
        reviewAverage: 4.9,
        reviewCount: 238,
      },
      category: {
        rootCategoryId: 1,
        rootCategoryName: '디자인',
        subCategoryId: 101,
        subCategoryName: '로고 디자인',
        thirdCategoryId: null,
        thirdCategoryName: null,
      },
      hasVideos: false,
      hasPackages: true,
      isPrime: false,
      isAvailableCalling: false,
      isCurrentUserBookmark: false,
      gigCardUuid: 'GIG_CARD_SEARCH_RESULT_2024060419040826913_juxdmj',
      kmongAd: null,
      isQualityGuaranteed: false,
    },
    {
      gigId: 478507,
      currentCategoryId: 112,
      title: '블로그제작,홈페이지형블로그,블로그스킨,블로그디자인',
      price: 38000,
      advertisementType: '',
      labels: [],
      images: [
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/OKiYr1712807758.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/47XhE1712807765.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/WqOrC1712807771.jpg',
        'https://d2v80xjmx68n4w.cloudfront.net/gigs/y960t1712807777.jpg',
      ],
      seller: {
        userId: 2396216,
        nickname: '조각구름',
        grade: 'MASTER',
        thumbnail: 'https://d2v80xjmx68n4w.cloudfront.net/members/thumbs/SGDzQ1669955210.jpg',
        isOnline: true,
        isAvailableTax: false,
        isFastReaction: true,
        isNowContactable: false,
      },
      review: {
        reviewAverage: 4.9,
        reviewCount: 713,
      },
      category: {
        rootCategoryId: 1,
        rootCategoryName: '디자인',
        subCategoryId: 112,
        subCategoryName: '블로그·카페 디자인',
        thirdCategoryId: null,
        thirdCategoryName: null,
      },
      hasVideos: false,
      hasPackages: true,
      isPrime: false,
      isAvailableCalling: false,
      isCurrentUserBookmark: false,
      gigCardUuid: 'GIG_CARD_SEARCH_RESULT_2024060419040826913_juxdmj',
      kmongAd: null,
      isQualityGuaranteed: false,
    },
  ],
  relatedKeywords: [
    '일러스트',
    '상세페이지',
    '로고',
    '캐릭터',
    '로고디자인',
    '배너',
    '포스터',
    '3D',
    'PPT',
  ],
};
