import api from '../api';
import kmongFetch from '../fetch/kmong-fetch';
import type { CounselAreaType } from './my-kmong';
import type { PageParams, PageResponse } from '../types';

export interface CounselProduct {
  id: number;
  sellerUserId: number;
  title: string;
  extensionNumber: string;
  oneLiner: string;
  labels: string[];
  costPerInterval: number;
  thumbnail: string;
  counselStatus: CounselProductStatus;
  reviewCount: number;
}

export type CounselProductStatus = 'AVAILABLE' | 'COUNSELING' | 'ABSENT';

export type GetCounselProductsResponse = PageResponse<CounselProduct>;

interface GetCounselProductsPayload extends PageParams {
  categoryId: number;
}

const getCounselProducts = async ({ page = 1, perPage = 16, categoryId }: GetCounselProductsPayload) => {
  const { data } = await api.get<GetCounselProductsResponse>('/api/msa/gig-app/counsel/v1/products', {
    params: {
      page,
      perPage,
      categoryId,
    },
  });

  return data;
};

export type GetCounselProductReviewsResponse = PageResponse<CounselReviewItem>;

interface GetCounselProductReviewsParams extends PageParams {
  counselProductId: number;
}

export interface CounselReviewItem extends CounselProductMarketReviewItem {
  counselDurationSeconds: number;
  counselArea: CounselAreaType;
}

const getCounselProductReviews = async ({
  counselProductId,
  page = 1,
  perPage = 10,
}: GetCounselProductReviewsParams) => {
  const { data } = await api.get<GetCounselProductReviewsResponse>(`/api/msa/order-app/counsel/review/v1/products/${counselProductId}/reviews`, {
    params: {
      page,
      perPage,
    },
  });

  return data;
};

export interface CounselProductMarketReviewItem {
  id: number;
  comment: string;
  user: {
    userId: number;
    nickname: string;
    thumbnail: string;
  };
  createdDateTime: string;
}

export type GetCounselProductMarketReviewsResponse = PageResponse<CounselProductMarketReviewItem>;

interface GetCounselProductMarketReviewsParams extends PageParams {
  counselProductId: number;
}

const getCounselProductMarketReviews = async ({ counselProductId, page = 1, perPage = 10 }: GetCounselProductMarketReviewsParams) => {
  const { data } = await api.get<GetCounselProductMarketReviewsResponse>(`/api/msa/gig-app/counsel/v1/products/${counselProductId}/market-reviews`, {
    params: {
      page,
      perPage,
    },
  });

  return data;
};

export interface GetCounselStatusResponse {
  productId: number;
  counselStatus: CounselProductStatus;
}

const getCounselStatus = async (counselProductId: number) => {
  const { data } = await api.get<GetCounselStatusResponse>(`/api/msa/gig-app/counsel/v1/products/${counselProductId}/counsel-status`);

  return data;
};

export interface GetExistCounselMobileNumberResponse {
  isExistMobileNumber: boolean;
}

const getExistCounselMobileNumber = async () => {
  const { data } = await api.get<GetExistCounselMobileNumberResponse>('/api/msa/user-app/counsel/user/v1/users/mobile-number/exists');

  return data;
};

export interface GetCounselAuthResponse {
  counselUser: CounselUser | null;
  /**
   * 전화 상담 이용 여부
   */
  isCounselUser: boolean;
  /**
   * 전화 상담 전문가 여부
   */
  isCounselSeller: boolean;
}

interface CounselUser {
  coinAmount: number;
}

const getCounselAuth = async () => {
  const { data } = await api.get<GetCounselAuthResponse>('/api/msa/user-app/counsel/user/v1/users/me');

  return data;
};

const postCounselDirectConnection = async (extensionNumber: string) => {
  const { data } = await api.post<{ userid: number }>('/api/msa/user-app/counsel/user/v1/users/direct-connection', {}, {
    params: {
      extensionNumber,
    },
  });

  return data;
};

export interface GetCoinChargePoliciesResponse {
  items: CoinChargePolicy[];
}

export interface CoinChargePolicy {
  id: number;
  chargeAmount: number;
  mileageAmount: number | null;
  isRecommend: boolean;
}

const getCoinChargePolicies = async () => {
  try {
    const { data } = await kmongFetch<GetCoinChargePoliciesResponse>('/api/msa/order-app/counsel/coin/v1/coins/coin-charge-policies', {
      cache: 'force-cache',
    });

    return data;
  } catch {
    return {
      items: [],
    };
  }
};

export interface GetCoinAmountAggregateResponse {
  totalCoinAmount: number;
  coinCashAmount: number;
  coinMileageAmount: number;
}

const getCoinAmountAggregate = async () => {
  const { data } = await kmongFetch<GetCoinAmountAggregateResponse>('/api/msa/order-app/counsel/coin/v1/coins/amount/aggregate', {
    cache: 'no-store',
    requiredAuth: true,
  });

  return data;
};

export interface GetTossPaymentCheckoutResponse {
  amount: number;
  payType: PaymentCheckoutPayType;
  tossOrderId: string;
}

export type PaymentCheckoutPayType = 'CARD' | 'BANK' | 'VBANK';

const getTossPaymentCheckouts = async (tossOrderId: string) => {
  const { data } = await kmongFetch<GetTossPaymentCheckoutResponse>(`/api/msa/order-app/counsel/payment/v1/checkouts/toss/${tossOrderId}`, {
    requiredAuth: true,
    cache: 'no-store',
  });

  return data;
};

export interface GetCounselOrderDetailResponse {
  counselOrderId: number;
  counselDurationSeconds: number;
  orderAmount: number;
  counselProduct: OrderDetailCounselProduct;
}

interface OrderDetailCounselProduct {
  id: number;
  title: string;
  extensionNumber: string;
  image: string;
  subCategoryId: number;
  subCategoryName: string;
}

export enum CounselOrderDetailErrorCode {
  NOT_FOUND_COUNSEL_ORDER_ERROR = 'NOT_FOUND_COUNSEL_ORDER_ERROR',
}

const getCounselOrderDetail = async (counselOrderId: number) => {
  const { data } = await kmongFetch<GetCounselOrderDetailResponse>(`/api/msa/order-app/counsel/order/v1/orders/${counselOrderId}`, {
    requiredAuth: true,
    cache: 'no-store',
  });

  return data;
};

export enum PostCounselAvailableNotificationsErrorCode {
  ALREADY_APPLIED_COUNSEL_PRODUCT_NOTIFICATION_ERROR = 'ALREADY_APPLIED_COUNSEL_PRODUCT_NOTIFICATION_ERROR',
}

const postCounselAvailableNotifications = async (counselProductId: number) => {
  const { data } = await api.post<{ counselProductId: number }>(`/api/msa/gig-app/counsel/v1/products/${counselProductId}/counsel-available-notifications`);

  return data;
};

export const phoneCounselingApi = {
  getCounselProducts,
  getCounselProductReviews,
  getCounselProductMarketReviews,
  getCounselStatus,
  getCounselAuth,
  postCounselDirectConnection,
  getExistCounselMobileNumber,
  postCounselAvailableNotifications,
  getCoinChargePolicies,
  getCoinAmountAggregate,
  getTossPaymentCheckouts,
  getCounselOrderDetail,
};
