import type { KmongV4ErrorResponse } from './kmong-error-legacy-instance.type';
import type { AxiosError, AxiosResponse } from 'axios';

type ErrorCode = string | number;

export interface ErrorData<T extends ErrorCode> {
  code: T;
  message: string;
  status?: number;
}

export interface ErrorResponseData<T extends ErrorCode = string, K = unknown> extends ErrorData<T> {
  status?: number;
  httpReason?: string;
  errorCode?: string;
  result?: string;
  data?: K | null;
}

export type KmongErrorResponse = AxiosError<ErrorResponseData>;

type KmongErrorWithAxiosError<T extends ErrorCode, K> = AxiosResponse<ErrorResponseData<T, K>>;

export class KmongErrorInstance<T extends ErrorCode = string, K = unknown> extends Error {
  response: KmongErrorWithAxiosError<T, K>;

  constructor(response: KmongErrorWithAxiosError<T, K>) {
    const message = 'message' in response.data ? response.data.message : 'error';
    super(message);

    this.response = response;
    Object.setPrototypeOf(this, KmongErrorInstance.prototype);
  }
}

export function isKmongV4Error(error: IntegrationKmongErrorInstance): error is KmongV4ErrorResponse {
  if (!error.response?.data) {
    return false;
  }

  return 'errors' in error.response.data;
}

export function isKmongError<T extends ErrorCode = string, K = unknown>(error: unknown): error is KmongErrorInstance<T, K> {
  return error instanceof KmongErrorInstance;
}

export type IntegrationKmongErrorInstance = KmongErrorResponse | KmongV4ErrorResponse;
