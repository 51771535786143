import { HeadersKeyEnum } from '@kmong-service/utils';
import { isServerSide, addPrefixWithToken } from '../../api';
import getAccessTokenFromCookie from './getAccessTokenFromCookie';
import getAllCookies from './getAllCookies';
import { appendServerSideHeaders } from './getNextHeaders';
import type { RequestInitWithNextConfig } from '../kmong-fetch';

const getHeaders = async ({ headers, requiredAuth }: Pick<RequestInitWithNextConfig, 'headers' | 'requiredAuth'>) => {
  let customHeaders = new Headers(headers);

  customHeaders.append('Content-Type', 'application/json');

  if (requiredAuth) {
    const accessToken = await getAccessTokenFromCookie();
    if (accessToken) {
      customHeaders.append(HeadersKeyEnum.X_KMONG_AUTHORIZATION, addPrefixWithToken(accessToken));
    }

    if (isServerSide()) {
      customHeaders = await appendServerSideHeaders(customHeaders);
    }

    customHeaders.append('Cookie', await getAllCookies());
  }

  return customHeaders;
};

export default getHeaders;
