import type { RequestInitWithNextConfig } from './kmong-fetch';

interface LogFetchParams {
  url: URL;
  config?: RequestInitWithNextConfig;
  response: unknown;
}

const kmongFetchLogging = async (params: LogFetchParams) => {
  if (process.env.FETCH_LOGGER === 'ON') {
    const { url, config, response } = params;
    // eslint-disable-next-line no-console
    console.info('[REQUEST] =>', {
      url,
      body: JSON.stringify(config?.body, null, 2),
      response: JSON.stringify(response, null, 2),
    });
  }
};

export default kmongFetchLogging;
