import { CLOUDFRONT_URL } from '@kmong-service/utils';
import api from '../api';
import type { LinkBase } from '../article-detail';
import type { SubCategoryWithRootCategory } from './categories';
import type { TempGig } from '../gig';
import type { TempPortfolioCardBase } from '../portfolio';

export interface GigsResponse {
  gigs: TempGig[];
}

interface GetPopularPortfoliosResponse {
  portfolios: TempPortfolioCardBase[];
}

interface GetRecommendCategoriesResponse {
  categories: SubCategoryWithRootCategory[];
}

const getRecentBookmarkGigs = async (takeCount: number) => {
  const { data } = await api.get<GigsResponse>('/api/msa/gig-app/gig/v1/recent-bookmark-gigs', {
    params: {
      takeCount,
    },
  });

  return data?.gigs;
};

interface GetPopularPortfoliosParams {
  rootCategoryId: number;
  takeCount: number;
}

const getPopularPortfolios = async ({ rootCategoryId, takeCount }: GetPopularPortfoliosParams) => {
  const { data } = await api.get<GetPopularPortfoliosResponse>(`/api/msa/gig-app/seller-profile/portfolio/v1/${rootCategoryId}/popular-portfolios`, {
    params: {
      takeCount,
    },
  });

  return data?.portfolios;
};

const getTopOrderGigs = async (takeCount: number) => {
  const { data } = await api.get<GigsResponse>('/api/msa/gig-app/gig/v1/top-order-gigs', {
    params: {
      takeCount,
    },
  });

  return data?.gigs;
};

interface GigIdWithTakeCountParams {
  gigId: number;
  takeCount: number;
}

const getQuicklyCompletedGigs = async ({ gigId, takeCount }: GigIdWithTakeCountParams) => {
  const { data } = await api.get<GigsResponse>('/api/msa/gig-app/gig/v1/quickly-completed-gigs', {
    params: {
      gigId,
      takeCount,
    },
  });

  return data?.gigs;
};

const getRecentViewedGigs = async (gigs: string) => {
  const { data } = await api.get<GigsResponse>('/api/msa/gig-app/gig/v1/recent-viewed-gigs', {
    params: {
      gigIds: gigs,
    },
  });

  return data?.gigs;
};

const getRecommendGigs = async (takeCount: number) => {
  const { data } = await api.get<GigsResponse>('/api/msa/gig-app/gig/v1/recommend-gigs', {
    params: {
      takeCount,
    },
  });

  return data?.gigs;
};

const getSimilarViewedGigs = async ({ gigId, takeCount }: GigIdWithTakeCountParams) => {
  const { data } = await api.get<GigsResponse>('/api/msa/gig-app/gig/v1/similar-viewed-gigs', {
    params: {
      gigId,
      takeCount,
    },
  });

  return data?.gigs;
};

const getRecommendCategories = async (takeCount: number) => {
  const { data } = await api.get<GetRecommendCategoriesResponse>('/api/msa/gig-app/category/v1/recommend-categories', {
    params: {
      takeCount,
    },
  });

  return data?.categories;
};

const getSimilarPurchasedGigs = async (takeCount: number) => {
  const { data } = await api.get<GigsResponse>('/api/msa/gig-app/gig/v1/similar-purchased-gigs', {
    params: {
      takeCount,
    },
  });

  return data?.gigs;
};

export interface PopularCategoryGigsParams {
  subCategoryId: number;
  takeCount: number;
}

const getPopularCategoryGigs = async ({ subCategoryId, takeCount }: PopularCategoryGigsParams) => {
  const { data } = await api.get<GigsResponse>('/api/msa/gig-app/gig/v1/top-order-gigs/sub-category', {
    params: {
      takeCount,
      subCategoryId,
    },
  });

  return data?.gigs;
};

export interface PopularCategoriesResponse {
  categories: PopularCategory[];
}

export interface PopularCategory {
  categoryId: number;
  categoryName: string;
}

const getPopularCategories = async (): Promise<PopularCategoriesResponse | null> => {
  const data = await fetch(`${CLOUDFRONT_URL}/assets/feeds/popularCategories.json`);

  return data.json();
};

export type RecommendPersonaType = 'SHOP_OWNER' | 'STORE_MANAGER' | 'EMPLOYEE';

export interface RecommendPersona {
  category: {
    categoryId: number;
    categoryName: string;
  };
  gig: TempGig;
}

interface RecommendPersonaGigsResponse {
  items: RecommendPersona[];
}

const getRecommendPersonaGigs = async (personaType: RecommendPersonaType) => {
  const { data } = await api.get<RecommendPersonaGigsResponse>('/api/msa/gig-app/gig/v1/recommend/persona-gigs', {
    params: {
      personaType,
    },
  });

  return data;
};

interface RecommendPrimeGigsResponse {
  gigs: TempGig[];
}

const getRecommendPrimeGigs = async () => {
  const { data } = await api.get<RecommendPrimeGigsResponse>('/api/msa/gig-app/gig/v1/recommend/prime-gigs');

  return data;
};

export interface ReviewSeller {
  userId: number;
  nickname: string;
}

interface GetSellersReviewThemeNicknamesResponse {
  items: ReviewSeller[];
}

const getSellersReviewThemeNicknames = async () => {
  const { data } = await api.get<GetSellersReviewThemeNicknamesResponse>('/api/msa/user-app/seller/v1/sellers/review-theme-nicknames');

  return data;
};

export interface PopularSecondCategory {
  title: string;
  backgroundColor: string;
  icon: string;
  linkInfo: LinkBase;
}

export interface PopularSecondCategoriesItem {
  title: string;
  highlightColor: string;
  highlightText: string;
  categories: PopularSecondCategory[];
}

interface PopularSecondCategoriesResponse {
  items: PopularSecondCategoriesItem[];
}

const getPopularSecondCategories = async (): Promise<PopularSecondCategoriesResponse | null> => {
  const data = await fetch(`${CLOUDFRONT_URL}/assets/main/popular-categories-section/popular_categories.json`);

  return data.json();
};

export interface GetCategoryNavSeasonalItemResponse {
  lottieUrl: string;
  name: string;
  linkInfo: LinkBase;
  isShow: boolean;
}

const getCategoryNavSeasonalItem = async (): Promise<GetCategoryNavSeasonalItemResponse | null> => {
  const data = await fetch(`${CLOUDFRONT_URL}/assets/main/category-icon-section/seasonal-item.json`);

  return data.json();
};

export const indexThemesApi = {
  getRecentBookmarkGigs,
  getPopularPortfolios,
  getTopOrderGigs,
  getQuicklyCompletedGigs,
  getRecentViewedGigs,
  getRecommendGigs,
  getSimilarViewedGigs,
  getRecommendCategories,
  getSimilarPurchasedGigs,
  getPopularCategoryGigs,
  getPopularCategories,
  getRecommendPersonaGigs,
  getRecommendPrimeGigs,
  getSellersReviewThemeNicknames,
  getPopularSecondCategories,
  getCategoryNavSeasonalItem,
};
