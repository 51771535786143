import api from '../api';
import type { AdditionalOptions } from '../order-detail/order-detail';
import type { OrderUserType, ResolutionMethodData, ResolutionType } from '../types';

export interface GetResolutionProblemResponse {
  resolutionMethods: ResolutionMethod[];
  order: {
    deliveredAt: string;
    deliveredDateTime: string;
  };
}

export interface ResolutionMethod {
  type: ResolutionType | null;
  resolutionMethod: ResolutionMethodData;
  resolutionProblemMethods: ResolutionProblemMethod[];
}

export interface ResolutionProblemMethod {
  situationalResolutionProblemId: number;
  resolutionProblemId: number;
  content: string;
  subContents?: SubContent[] | null;
}

export interface SubContent {
  id: number;
  name: string;
  description: string;
}

export interface GetResolutionProblemProps {
  orderId: number;
  userType: OrderUserType;
}

const getResolutionProblem = async ({ orderId, userType }: GetResolutionProblemProps) => {
  // TODO 추후 jwt 완료되면 jwt 토큰 값 넣고 X-Kmong-Authorization 헤더 붙여야 함
  const { data } = await api.get<GetResolutionProblemResponse>(`/api/msa/order-app/order/v1/orders/${orderId}/progress/${userType}/resolution-problem`);

  return data;
};

export interface AdditionalSelectedOption {
  id?: number;
  description?: string;
  type?: 'CUSTOM';
  title: string;
  price: string;
  days: string;
  quantity: number;
  active?: boolean;
}

export interface PostMutualRequestProps {
  message: string;
  resolutionData: ResolutionData;
  orderProgressId: number;
  additionalOptions: AdditionalOption[];
  selectedOptions: AdditionalSelectedOption[];
}

export interface ResolutionData {
  situationalResolutionProblemId: number;
  resolutionProblemId: number;
  resolutionMethod: ResolutionMethodData;
  resolutionProblemMessage: string;
  subContent: SubContent;
  days: string;
  content: string;
  expectedDeliveredAt: string;
  changedExpectedDeliveredAt: string;
}

export interface AdditionalOption {
  type: ResolutionType;
  title: string;
  price: number;
  days: string;
  quantity: number;
}

const postRequestTimeModification = async (
  orderId: number,
  payload: PostMutualRequestProps,
) => {
  await api.post(`/api/order/v1/orders/${orderId}/progress/time-modification/request`, payload);
};

const postRequestAdditionalPay = async (
  orderId: number,
  payload: PostMutualRequestProps,
) => {
  await api.post(`/api/order/v1/orders/${orderId}/progress/additional-pay/request`, payload);
};

export interface PostRequestAdditionalPayDirectOrderIdPayload {
  OID: number;
  selected_options: AdditionalOptions[];
  total_price: number;
  orderProgressId: number | null;
}

export interface PostRequestAdditionalPayDirectOrderIdResponse {
  directOrderId: number;
}

const postRequestAdditionalPayDirectOrderId = async (payload: PostRequestAdditionalPayDirectOrderIdPayload) => {
  if (!payload.orderProgressId) {
    throw new Error();
  }

  const { data } = await api.post<PostRequestAdditionalPayDirectOrderIdResponse>(`/api/order/v1/orders/${payload.OID}/progress/additional-pay/${payload.orderProgressId}/direct-orders`, payload);

  return data.directOrderId;
};

const postRequestMutualCancellation = async (
  orderId: number,
  payload: PostMutualRequestProps,
) => {
  await api.post(`/api/order/v1/orders/${orderId}/progress/mutual-cancellation/request`, payload);
};

const putRequestTimeModificationRetract = async (
  orderId: number,
  orderProgressId: number | null,
) => {
  if (!orderProgressId) {
    throw new Error();
  }
  await api.put(`/api/order/v1/orders/${orderId}/progress/time-modification/retract`, {
    orderProgressId,
  });
};

const putRequestAdditionalPayRetract = async (
  orderId: number,
  orderProgressId: number | null,
) => {
  if (!orderProgressId) {
    throw new Error();
  }
  await api.put(`/api/order/v1/orders/${orderId}/progress/additional-pay/retract`, {
    orderProgressId,
  });
};

const putRequestAdditionalPayReject = async (
  orderId: number,
  orderProgressId: number | null,
) => {
  if (!orderProgressId) {
    throw new Error();
  }
  await api.put(`/api/order/v1/orders/${orderId}/progress/additional-pay/reject`, {
    orderProgressId,
  });
};

export interface AdditionalPayRequestResponse {
  gigOptions: GigOption[];
}

export interface GigOption {
  id: number;
  title: string;
  description: string;
  price: string;
  days: number;
}

export interface GigOptionWithQuantity extends GigOption {
  quantity: number;
}

const getAdditionalPayRequestOptions = async (gigLogId: number) => {
  const { data } = await api.get<AdditionalPayRequestResponse>(`/api/gig/v1/gig-logs/${gigLogId}/gig-options`);

  return data.gigOptions ?? [];
};

export interface GetUserRefundAccountsEligibilityResponse {
  isAccountRefund: boolean;
}

const getUserRefundAccountsEligibility = async (orderId: number) => {
  const { data } = await api.get<GetUserRefundAccountsEligibilityResponse>(`/api/msa/order-app/payment/v1/payments/buyer/refund/user-refund-accounts/eligibility/${orderId}`);

  return data;
};

const resolutionProblemApis = {
  getResolutionProblem,
  postRequestTimeModification,
  postRequestAdditionalPay,
  postRequestAdditionalPayDirectOrderId,
  postRequestMutualCancellation,
  putRequestTimeModificationRetract,
  putRequestAdditionalPayRetract,
  putRequestAdditionalPayReject,
  getAdditionalPayRequestOptions,
  getUserRefundAccountsEligibility,
};

export default resolutionProblemApis;
