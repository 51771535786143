import type { ErrorData } from './kmong-error-instance.type';
import type { KmongFetchErrorResponse } from '../fetch/kmong-fetch';

type ErrorCode = string | number;

export class KmongFetchError<T extends ErrorCode = string> extends Error {
  constructor(response: KmongFetchErrorResponse<T>) {
    const message = JSON.stringify(response);
    super(message);
  }
}

function isKmongFetchError(error: string): boolean {
  try {
    const parsedError = JSON.parse(error as string);
    const { data } = parsedError;

    return Boolean(parsedError && data.code && data.message);
  } catch {
    return false;
  }
}

/**
 * 주어진 Error 객체에서 KmongFetchError 데이터를 추출합니다.
 *
 * @param error - KmongFetchError 데이터를 추출할 Error 객체입니다.
 * @returns 해당 오류가 KmongFetchError인 경우 KmongFetchError 데이터를 반환하고, 그렇지 않으면 null을 반환합니다.
 *
 * @example
 * import { getKmongFetchError } from '@kmong/athens/lib';
 *
 * const error = new Error('{"status":404,"data":{"code":404,"message":"Not Found"}}'); (이는 에러가 내려왔다고 가정)
 *
 * // 오류가 Kmong fetch error인지 확인하고 데이터를 추출합니다.
 * const errorData = getKmongFetchError<ErrorCode>(error);
 *
 * if (errorData) {
 *   console.log(`Error Code: ${errorData.code}`);
 *   console.log(`Error Message: ${errorData.message}`);
 * } else {
 *   console.log('The error is not a Kmong fetch error.');
 * }
 */
export function getKmongFetchError<T extends ErrorCode = string>(error: Error): ErrorData<T> | null {
  if (isKmongFetchError(error.message)) {
    const parsedError = JSON.parse(error.message) as KmongFetchErrorResponse<ErrorData<T>>;

    return parsedError.data ?? null;
  }

  return null;
}
