import { isLocal } from '../api';
import { getRewriteURL } from '../api/lib/utils';
import { KmongFetchError } from '../types/kmong-fetch-error.type';
import kmongFetchLogging from './kmong-fetch-logging';
import { checkAuthForAuthRequired } from './utils/checkAuthForAuthRequired';
import getHeaders from './utils/getHeaders';

interface NextFetchRequestConfig {
  revalidate?: number | false;
  tags?: string[];
}

type RecordParams<T> = {
  [K in keyof T]: T[K];
};

export interface RequestInitWithNextConfig extends RequestInit {
  requiredAuth?: boolean;
  data?: unknown;
  next?: NextFetchRequestConfig | undefined;
  params?: RecordParams<unknown>;
}

interface KmongFetchResponse<T> {
  data: T;
}

export interface KmongFetchErrorResponse<T = unknown> {
  status: number;
  data: T;
}

const kmongFetch = async <T>(reqURL: string, config?: RequestInitWithNextConfig): Promise<KmongFetchResponse<T>> => {
  const { baseURL, url } = getRewriteURL(reqURL);
  const {
    params, data, headers, requiredAuth = false, ...configRest
  } = config ?? {} as RequestInitWithNextConfig;

  const newUrl = new URL(url, baseURL);
  const searchParams = new URLSearchParams(params ?? '');

  newUrl.search = searchParams.toString();

  // TODO. v4 로그인 마이그레이션 이후 로컬 조건 제거 필요
  if (isLocal()) {
    await checkAuthForAuthRequired(requiredAuth);
  }

  const requestConfig: RequestInitWithNextConfig = {
    ...configRest,
    headers: await getHeaders({
      headers,
      requiredAuth,
    }),
    credentials: 'include',
    body: data ? JSON.stringify(data) : undefined,
  };

  const response = await fetch(newUrl, requestConfig);

  if (response.status > 500) {
    throw new KmongFetchError({
      status: response.status,
      data: response.statusText,
    });
  }

  const json = await response.json();

  kmongFetchLogging({
    url: newUrl,
    config: requestConfig,
    response: json,
  });

  if (response.status !== 200) {
    throw new KmongFetchError({
      status: response.status,
      data: json,
    });
  }

  return {
    data: json,
  };
};

export default kmongFetch;
