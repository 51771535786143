import React, { useState, useEffect } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  colors,
  breakpoints,
  Button,
  MenuIcon,
  Logo,
} from '@kmong/ui';
import { Link } from 'gatsby';
import useWindowScrollTop from '../lib/hooks/useWindowScrollTop';
import MobileNav from './MobileNav';

interface HeaderBlockProps {
  clearOnTop: boolean;
  isWindowTop: boolean;
  darkMode: boolean;
}

const HeaderBlock = styled.header<HeaderBlockProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 76px;
  transition: background-color .3s, box-shadow 0.3s;
  z-index: 9;

  ${(props) => (!props.clearOnTop || (props.clearOnTop && !props.isWindowTop))
    && css`
      ${props.darkMode ? css`background-color: rgba(29, 29, 31, 0.72);` : css`background-color: rgba(255, 255, 255, 0.72);`};
      box-shadow: 0 1px 2px rgba(41, 42, 43, 0.1);
    `};

  @media screen and (max-width: ${breakpoints.medium}px){
    height: 48px;
  }
`;

const HeaderMain = styled.div`
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
`;

const Brand = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  justify-content: center;

  a, img {
    width: 85px;
    height: 26px;
  }

  @media screen and (max-width: ${breakpoints.medium}px){
    width: 100%;
  }
`;

const Nav = styled.nav<{ whiteText?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: ${breakpoints.medium}px){
    display: none;
  }

  a {
    font-size: 14px;
    padding: 0 16px;
    text-decoration: none;
    font-weight: 500;
    color: ${(props) => (props.whiteText ? colors.gray[100] : colors.gray[900])};
  }
`;

const MobileMenuButtonWrapper = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  align-items: center;
  padding-left: 3px;

  @media screen and (max-width: ${breakpoints.medium}px){
    display: flex;
  }
`;

export interface HeaderProps {
  clearOnTop?: boolean;
  whiteText?: boolean;
  darkMode?: boolean;
}

function Header({
  clearOnTop = false,
  whiteText,
  darkMode = false,
}: HeaderProps) {
  const [isWhiteText, setIsWhiteText] = useState(whiteText);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const isWindowTop = useWindowScrollTop();

  useEffect(() => {
    if (darkMode) {
      setIsWhiteText(true);
    } else if (whiteText) {
      setIsWhiteText(isWindowTop ? whiteText : !whiteText);
    } else {
      setIsWhiteText(false);
    }
  }, [isWindowTop, darkMode, whiteText]);

  return (
    <>
      <HeaderBlock
        clearOnTop={clearOnTop}
        darkMode={darkMode}
        isWindowTop={isWindowTop}
      >
        <HeaderMain>
          <MobileMenuButtonWrapper>
            <Button
              icon
              rounded
              dark={darkMode || (whiteText && isWindowTop)}
              size="large"
              variant="text"
              onClick={() => setShowMobileNav(true)}
            >
              <MenuIcon />
            </Button>
          </MobileMenuButtonWrapper>
          <Brand>
            <Link to="/">
              <Logo
                variants={isWhiteText ? 'light' : 'dark'}
                width={85}
              />
            </Link>
          </Brand>
          <Nav whiteText={darkMode || (whiteText && isWindowTop)}>
            <Link
              target="_blank"
              to="https://kmong.career.greetinghr.com/"

            >
              채용
            </Link>
          </Nav>
        </HeaderMain>
      </HeaderBlock>
      {showMobileNav && <MobileNav onClose={() => setShowMobileNav(false)} />}
    </>
  );
}

export default Header;
