export enum QualityGuaranteedActionTypeLabel {
  QUALITY_GUARANTEED_REQUEST = '품질보장 신청',
  QUALITY_GUARANTEED_ONGOING = '품질보장 검토중',
  QUALITY_GUARANTEED_RETRACTION = '품질보장 신청 철회',
  QUALITY_GUARANTEED_REJECTION = '품질보장 신청 반려',
  QUALITY_GUARANTEED_ACCEPTATION = '품질보장 처리 완료',
}

export type QualityGuaranteedActionType = keyof typeof QualityGuaranteedActionTypeLabel;

export interface OrderStatusType<StatusType = string, StatusLabel = string> {
  isQualityGuaranteed: boolean;
  qualityGuaranteedActionType: QualityGuaranteedActionType | null;
  status: StatusType;
  statusLabel: StatusLabel;
  statusGuideText: string;
  hasRequireAnswers: boolean;
  isCurrentlyMutualCancellationRequest: boolean;
  isLateDelivery: boolean;
  isUnread: boolean;
  isWrittenReview: boolean;
  isWrittenReviewBuyer: boolean;
  isAvailableToWriteReview: boolean;
  isAvailableToWriteReviewBuyer: boolean;
  currentlyStatusChangedUserType: ActionUserType;
  isDirectlyDelivered: boolean;
  hasBuyerRefundAccount: boolean | null;
  isBuyerRefundCompleted: boolean;
}

export const ORDER_USER_TYPES = {
  SELLER: 'SELLER',
  BUYER: 'BUYER',
} as const;

export type OrderUserType = keyof typeof ORDER_USER_TYPES;

export const ACTION_USER_TYPES = {
  SELLER: 'SELLER',
  BUYER: 'BUYER',
  SYSTEM: 'SYSTEM',
} as const;

export type ActionUserType = keyof typeof ACTION_USER_TYPES;

export const COMMON_ORDER_TYPE = {
  GIG: 'GIG',
  CUSTOM_PROJECT: 'CUSTOM_PROJECT',
} as const;

export type OrderType = keyof typeof COMMON_ORDER_TYPE;

export const COMMON_PRODUCT_TYPE = {
  GIG: 'GIG',
  VOD: 'VOD',
  EBOOK: 'EBOOK',
  TEMPLATE: 'TEMPLATE',
  CUSTOM_PROJECT: 'CUSTOM_PROJECT',
} as const;

export type ProductType = keyof typeof COMMON_PRODUCT_TYPE;

export type GIGProductTypes = Omit<ProductType, 'EBOOK' | 'VOD' | 'TEMPLATE'>;

export const PRODUCT_LABELS: Record<ProductType, string> = {
  GIG: '작업물',
  CUSTOM_PROJECT: '작업물',
  EBOOK: '전자책',
  VOD: '수강권',
  TEMPLATE: '템플릿',
} as const;

export interface InstantDeliveryProductType {
  contentId: number | null;
  remainingDays: number | null;
}

export interface OrderItemProductType {
  orderType: OrderType;
  typeId: number;
  productType: ProductType;
  gigId: number;
  gigLogId: number;
  image: string;
  title: string;
  instantDeliveryProduct: InstantDeliveryProductType | null;
}

export interface OrderUserInfo {
  userId: number;
  image: string | null;
  nickname: string;
  safeNumber?: string | null;
  isAuthAvailableTaxRequest?: boolean;
}

interface BuyerOrganization {
  organizationId: number | null;
  companyName: string | null;
  businessUser: {
    roleType: string | null;
  } | null;
}

export interface BuyerOrderUserInfo extends OrderUserInfo {
  organization: BuyerOrganization | null;
}

export interface OrderDateTimes {
  startedAt: string | null;
  completedAt: string | null;
  completedDotAt: string | null;
  deliveredAt: string | null;
  expectedCompletedAt: string | null;
  expectedSysCanceledAt: string | null;
  expectedSysCompletedAt: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface OrderHistoryCount {
  allCount: number;
  notInstCount: number;
  ongoingCount: number;
  deliveredCount: number;
  cancelRequestWithResolutionProblemCount: number;
  lateDeliveryCount: number;
  completedCount: number;
  canceledCount: number;
  notReviewedCount: number;
  inrevisionCount: number;
}
