export interface NaverWcsItem {
  id: string;
  name: string;
  category?: string;
}

export type NaverWcsType = 'view_product' | 'sign_up';

export interface NaverWcsConversion {
  type: NaverWcsType;
  value?: string;
  id?: string;
  items?: NaverWcsItem[];
}

declare global {
  interface Window {
    wcs?: {
      trans: (conversion: NaverWcsConversion) => void;
      inflow: () => void;
    };
    wcs_add: {
      wa: string;
    };
    _nasa: unknown;
    wcs_do: () => void;
  }
}

const initializeWcsAdd = () => {
  window.wcs_add = window.wcs_add || {};
  window.wcs_add.wa = process.env.NEXT_PUBLIC_NAVER_WCS_ID ?? '';
};

const isConversionPage = () => window.location.pathname === '/welcome-signup';

const initializeNaverWcsTracking = () => {
  initializeWcsAdd();

  // eslint-disable-next-line no-underscore-dangle
  if (!window._nasa) {
    // eslint-disable-next-line no-underscore-dangle
    window._nasa = {};
  }

  if (isConversionPage()) {
    return;
  }

  if (window.wcs) {
    window.wcs.inflow();
    window.wcs_do?.();
  }
};

const conversionSignup = () => {
  if (!window.wcs) {
    return;
  }

  initializeWcsAdd();

  const conv: NaverWcsConversion = {
    type: 'sign_up',
  };

  window.wcs.trans(conv);
};

export const trackViewProduct = (item: NaverWcsItem) => {
  if (!window.wcs) {
    return;
  }

  initializeWcsAdd();

  const conv: NaverWcsConversion = {
    type: 'view_product',
    items: [item],
  };

  window.wcs.trans(conv);
};

export default {
  initializeNaverWcsTracking,
  trackViewProduct,
  conversionSignup,
};
