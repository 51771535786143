import type { Rating } from '@kmong/rest-client/src/review';
import api from '../api';
import type { GigDetailForDesktop, GigDetailForMobile } from './platform';
import type { Gig, TempGig } from '../gig';

export type GetGigDetailResponse = GigDetailForDesktop | GigDetailForMobile;

/**
 * @description 모듈 테마 ID
 * 179: 방금 본 서비스
 * 185: 인기순 서비스
 * 186: 평점순 서비스
 * 191: 같이보면 좋은 서비스
 * 193: 좋은 리뷰로 검증된 서비스
 * 194: 인기 포트폴리오가 있는 서비스
 * 195: 크몽로그 (keyword에 관한 유용한 정보들)
 * 300: 프라임 위젯
 */
export type ThemeId = 179 | 180 | 181 | 182 | 183 | 185 | 186 | 191 | 193 | 194 | 195 | 300;

export interface GetGigDetailParams {
  PID: number;
  is_money_plus_path?: boolean;
  signal?: AbortSignal;
  customUA?: string;
}

const getGigDetail = async ({
  PID,
  is_money_plus_path = false,
  signal,
  customUA,
}: GetGigDetailParams) => {
  const { data } = await api.get<GetGigDetailResponse>(`/api/v5/gigs/${PID}`, {
    params: {
      is_money_plus_path,
    },
    signal,
    ...(customUA && {
      headers: {
        'User-Agent': customUA,
      },
    }),
  });

  return data;
};

interface UserInfo {
  nickname: string;
}

interface PriceInfo {
  minPrice: number;
  maxPrice: number;
}

interface CategoryInfo {
  rootCategoryId: number;
  rootCategoryName: string;
  subCategoryId: number;
  subCategoryName: string;
  thirdCategoryId: number | null;
  thirdCategoryName: string | null;
}

interface Review {
  score: number;
  nickname: string;
  comment: string;
}

interface ReviewInfo {
  reviewAverage: number;
  reviewCount: number;
  topActiveReviewScore: number;
  reviews: Review[];
}

interface FAQ {
  question: string;
  answer: string;
}

interface OrderInfo {
  totalCompletedOrderCount: number;
}

export interface GigDetailSEO {
  title: string;
  description: string;
  image: string;
  userInfo: UserInfo;
  priceInfo: PriceInfo;
  categoryInfo: CategoryInfo;
  reviewInfo?: ReviewInfo;
  orderInfo?: OrderInfo;
  faqs: FAQ[];
}

const getGigDetailSEO = async (PID: number, signal?: AbortSignal) => {
  const { data } = await api.get<GigDetailSEO>(`/api/msa/gig-app/gig/v1/SEO/${PID}`, {
    signal,
  });

  return data;
};

export interface GetPublicCouponParams {
  PID: number;
}

export const discountTypes = {
  PERCENT: 'PERCENT',
  AMOUNT: 'AMOUNT',
} as const;

export type DiscountType = typeof discountTypes[keyof typeof discountTypes];

export const termsTypes = {
  DATE: 'DATE',
  DAYS: 'DAYS',
} as const;

export type TermsType = typeof termsTypes[keyof typeof termsTypes];

export interface CouponDiscountDetailType {
  type: DiscountType;
  amount: number;
  minimumAmount: number;
  maximumAmount: number;
}

interface Terms {
  type: TermsType;
  date: string | null;
  days: number | null;
  termsText: string;
  startedDateTime: string;
  endedDateTime: string;
}

interface Available {
  deviceType: 'ALL';
  categories: number[];
  gigs: number[];
  countPerUser: number;
  availableText: string;
}
export interface PublicCouponType {
  id: number;
  provider: string;
  title: string;
  type: 'PUBLIC_COUPON' | 'COMMON_COUPON';
  isIssued: boolean;
  discount: CouponDiscountDetailType;
  terms: Terms;
  available: Available;
}

export interface GetPublicCouponResponse {
  items: PublicCouponType[];
}

const getPublicCoupon = async ({ PID }: GetPublicCouponParams) => {
  try {
    const { data } = await api.get<GetPublicCouponResponse>('/api/msa/order-app/payment/v1/payments/buyer/coupon/public-coupons', { params: { gigId: PID } });

    return data.items;
  } catch {
    return [];
  }
};

// 찜하기 표시
const saveBookMarks = async (PID: number) => api.post(`/api/v5/gig/${PID}/bookmark`);

// 찜하기 해제
const deleteBookMarks = async (PID: number) => api.delete(`/api/v5/gig/${PID}/bookmark`);

interface SaveVacationNotificationRequest {
  PID: number;
  action: string;
}

// 휴가 알림
interface SaveVacationResponse {
  meta: {
    action: string;
  };
}
const saveVacationNotification = async (payload: SaveVacationNotificationRequest) => {
  try {
    const { data: { meta: { action } } } = await api.post<SaveVacationResponse>('/setVacationNotify', payload);

    return action;
  } catch {
    return 'error';
  }
};

export interface PhotoReviewsResponse {
  total: number;
  previous_page_link: string | null;
  next_page_link: string | null;
  last_page: number;
  portfolios: Rating[];
}

const getPhotoReviews = async ({
  PID,
  page,
}: {
  PID: number;
  page: number;
}) => {
  const { data } = await api.get<PhotoReviewsResponse>(`/api/v5/gigs/${PID}/ratings/live-portfolios`, {
    params: {
      page,
      offset: 9,
      order_by: 'REPLIED_AT_DESC',
    },
  });

  return data;
};

const getPhotoImage = async ({
  PID, RID, FID,
}: {
  PID: number;
  RID: number;
  FID: number;
}) => {
  const { data } = await api.get<{ url: string }>(`/api/v5/gigs/${PID}/ratings/${RID}/files/${FID}`);

  return data.url;
};

export interface CountWordsResponse {
  meta: {
    status: number;
    msg: string;
  };
  data: {
    file_extension: 'txt' | 'doc';
    word_length: number;
    character_length: number;
  };
}

const countWords = async (formData: FormData) => {
  const { data } = await api.post<CountWordsResponse>('/count_words', formData);

  return data;
};

export interface GetByPathResponse {
  gigs: Gig[];
}

const getByPath = async (path: string) => {
  const { data } = await api.get<GetByPathResponse>(`/api/${path}`);

  return data.gigs;
};

export interface PersonalizedGigParams {
  gigId?: number;
  takeCount?: number;
  gigIds?: string;
  subCategoryId?: number;
}

interface GetPersonalizedGigProps {
  path: string;
  params: PersonalizedGigParams;
}

interface GetPersonalizedGigResponse {
  gigs: TempGig[];
}

const getPersonalizedGig = async ({
  path,
  params,
}: GetPersonalizedGigProps) => {
  const { data } = await api.get<GetPersonalizedGigResponse>(`/api/msa${path}`, { params });

  return data.gigs ?? [];
};

const getVacationReturnNotificationRequest = async (gigId: number) => {
  const { data } = await api.get<boolean>(`/api/msa/gig-app/gig/v1/gigs/${gigId}/user/vacation-return/notification-request`, {
    params: {
      gigId,
    },
  });

  return data ?? false;
};

export interface GetReviewAverageResponse {
  review: ReviewAverage;
}

interface ReviewAverage {
  reviewAverage: number;
  reviewCount: number;
  reviewScoresAverage: ReviewScoresAverage[];
}

export interface ReviewScoresAverage {
  type: ReviewScoreAverageType;
  reviewScoreAverage: number;
}

export type ReviewScoreAverageType = 'RESPONSE_TIME' | 'MANNER' | 'SATISFACTION';

const getReviewAverage = async (gigId: number) => {
  const { data } = await api.get<GetReviewAverageResponse>(`/api/msa/gig-app/gig/v1/${gigId}/review-average`);

  return data;
};

export interface GetRecommendTopReviewsResponse {
  reviews: RecommendTopReviews[];
}

interface RecommendTopReviews {
  gigId: number;
  category: CategoryInfo;
  review: {
    reviewAverage: number;
    reviewComment: string;
    reviewerNickname: string;
    reviewCreatedAt: string;
  };
}

const getRecommendTopReviews = async (gigId: number) => {
  const { data } = await api.get<GetRecommendTopReviewsResponse>('/api/msa/gig-app/gig/v1/recommend-top-reviews', {
    params: {
      gigId,
      takeCount: 5,
    },
  });

  return data;
};

export * from './common-module/gigDetailCommonModule';
export * from './gig-modules';
export * from './platform';

export const gigDetailApi = {
  getGigDetail,
  getGigDetailSEO,
  getPublicCoupon,
  saveBookMarks,
  deleteBookMarks,
  saveVacationNotification,
  countWords,
  getPhotoReviews,
  getPhotoImage,
  getByPath,
  getPersonalizedGig,
  getVacationReturnNotificationRequest,
  getReviewAverage,
  getRecommendTopReviews,
};
