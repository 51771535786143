export enum CookiesKeyEnum {
  HIDE_SURVEY_WIDGET = 'hideSurveyWidget',
  KMONG_FIRST_REFERRER = 'kmongFirstReferrer',
  KMONG_LAST_REFERRER = 'kmongLastReferrer',
  MONEY_PLUS_SIGNUP = 'moneyPlusSignup',
  KMONG_ACCESS_TOKEN = 'Kmong-AccessToken',
  KMONG_REFRESH_TOKEN = 'Kmong-RefreshToken',
  HIDE_CATEGORY_CHOOSE_TOOLTIP = 'hideCategoryChooseTooltip',
  HIDE_PLACE_FILTER_TOOLTIP = 'hidePlaceFilterTooltip',
  HIDE_META_PLACE_FILTER_TOOLTIP = 'hideMetaPlaceFilterTooltip',
  HIDE_SCHEDULE_UPDATE_POPUP = 'hideScheduleUpdatePopup',
  HIDE_SELLER_SCHEDULE_WIDGET = 'hideSellerScheduleWidget',
  HIDE_INBOX_ONBOARDING_MODAL = 'hideInboxOnboardingModal',
  HIDE_AI_ONBOARDING_MODAL = 'hideAiOnboardingModal',
  HIDE_KMONG_BIZ_POPUP = 'hideKmongBizPopup',
  HIDE_KMONG_BIZ_TOOLTIP = 'hideKmongBizTooltip',
  ALREADY_SIGNUP_USER = 'alreadySignupUser',
  ALREADY_SIGNUP_USERS = 'alreadySignupUsers',
  GCLID = 'gclid',
  GCLID_ENTERED_TIME = 'gclidEnteredTime',
  HIDE_PAYBACK_POPUP = 'hidePaybackPopup',
  HIDE_PHONE_COUNSELING_FIXED_BANNER = 'hidePhoneCounselingFixedBanner',
  HIDE_AI_MOBILE_FIXED_BANNER = 'hideAiMobileFixedBanner',
  LOGIN_FAILED_COUNT = 'loginFailedCount',
  LOGIN_LOCKOUT_TIMER = 'loginLockoutTimer',
}
