import { HeadersKeyEnum } from '@kmong-service/utils';
import { isServerSide } from '../../api';

/**
 * 서버에서 next의 headers 정보를 가져오는 함수
 * @description app router에서만 사용 가능한 함수입니다.
 * @returns Headers 객체
 */
export const getNextServerHeaders = async () => {
  if (isServerSide()) {
    const { headers } = await import('next/headers');

    return headers();
  }

  return null;
};

/**
 * 서버에서 next의 headers 정보를 가져와 customHeaders에 추가하는 함수
 * @description app router에서만 사용 가능한 함수입니다.
 * @param customHeaders - Headers 객체
 * @returns customHeaders
 */
export const appendServerSideHeaders = async (customHeaders: Headers) => {
  const nextHeaders = await getNextServerHeaders();
  const authorization = nextHeaders?.get(HeadersKeyEnum.X_KMONG_AUTHORIZATION);

  if (authorization) {
    customHeaders.append(HeadersKeyEnum.X_KMONG_AUTHORIZATION, authorization);
  }

  customHeaders.append('user-agent', nextHeaders?.get('user-agent') ?? '');
  customHeaders.append(HeadersKeyEnum.X_AMZN_TRACE_ID, nextHeaders?.get(HeadersKeyEnum.X_AMZN_TRACE_ID) ?? '');
  customHeaders.append(HeadersKeyEnum.X_FORWARDED_FOR, nextHeaders?.get(HeadersKeyEnum.X_FORWARDED_FOR) ?? '');
  customHeaders.append(HeadersKeyEnum.X_USER_AGENT, 'next');

  return customHeaders;
};
